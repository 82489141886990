<template>
  <div class="d-flex">
    <modal-step-by-step
      v-if="device !== 'mobile' && !modalTitle.includes('Edit')"
      :modalTitle="'PeoplePage.AddPerson'"
      :isOpen="isOpen"
      :stepPosition="1"
    />
    <b-sidebar
      id="people-sidebar-right"
      :class="[!modalTitle.includes('Edit') ? 'sidebar-add-people' : 'sidebar-edit-people']"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '60vw' : '100vw'"
      v-model="isOpen"
      @hidden="onHidden"
    >
      <template #header>
        <div class="header-sidebar">
          <div class="sidebar-title">
            {{ $t(modalTitle) }}
          </div>
        </div>
        <div
          v-if="modalTitle.includes('Edit')"
          class="separator"
        ></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('PeoplePage.Name')"
            label-for="firstName"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              id="firstName"
              v-model="firstName"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.firstName"
              lazy-formatter
              :formatter="
                () => {
                  states.firstName = firstName !== '';
                  return firstName;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('AccountSetting.LastName')"
            label-for="lastName"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              id="lastName"
              v-model="lastName"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.lastName"
              lazy-formatter
              :formatter="
                () => {
                  states.lastName = lastName !== '';
                  return lastName;
                }
              "
            />
          </b-form-group>
        </b-col>
        <div class="input-email">
          <b-form-group
            :label="$t('SupportPage.Email')"
            label-for="email"
            :invalid-feedback="
              flagEmailEqual
                ? $t('PeoplePage.EqualEmail')
                : email == ''
                ? $t('RequiredField')
                : $t('PeoplePage.InvalidEmail')
            "
          >
            <b-form-input
              id="email"
              v-model="email"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.email"
              lazy-formatter
              :formatter="
                () => {
                  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                  states.email =
                    email !== '' && regexEmail.test(email) && flagEmailEqual === false
                      ? true
                      : false;
                  return email;
                }
              "
              @input="
                () => {
                  flagEmailEqual = false;
                  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                  states.email = email !== '' && regexEmail.test(email) ? true : false;
                  return email;
                }
              "
              type="email"
            />
          </b-form-group>
          <div
            v-if="modalTitle.includes('Edit')"
            :class="states.email == false ? 'icon btn-email mandatory' : 'icon btn-email'"
            @click="sendEmailAction()"
          >
            <EmailIcon />
          </div>
        </div>
        <div class="input-phone">
          <b-form-group
            :label="$t('DepartmentPage.Telephone')"
            label-for="phone"
            :invalid-feedback="
              flagPhoneEqual
                ? $t('PeoplePage.EqualPhone')
                : phone == ''
                ? $t('RequiredField')
                : $t('PeoplePage.InvalidPhone')
            "
          >
            <b-form-input
              id="phone"
              v-model="phone"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.phone"
              v-mask="'(##) # ####-####'"
              lazy-formatter
              :formatter="
                () => {
                  if (phone !== '') {
                    const regexPhone = /^\d{11}$/;
                    const newPhone = phone.replace(/[\(\)\s\-]/g, '');
                    states.phone =
                      regexPhone.test(newPhone) && flagPhoneEqual === false ? true : false;
                  } else {
                    states.phone = null;
                  }
                  return phone;
                }
              "
              @input="
                () => {
                  const regexPhone = /^\d{11}$/;
                  const newPhone = phone.replace(/[\(\)\s\-]/g, '');
                  if (userInfos.phone && phone.length === 16 && userInfos.phone !== newPhone) {
                    flagPhoneEqual = false;
                  }
                  if (phone !== '') {
                    states.phone =
                      regexPhone.test(newPhone) && flagPhoneEqual === false ? null : false;
                  }
                  return phone;
                }
              "
            />
          </b-form-group>
          <div
            v-if="modalTitle.includes('Edit')"
            :class="states.phone == false ? 'icon btn-phone mandatory' : 'icon btn-phone'"
            @click="callAction()"
          >
            <PhoneIcon />
          </div>
        </div>
        <b-col
          md="12"
          class="form-group"
        >
          <Multiselect
            :label="$t('DepartmentPage.Department')"
            :fieldPlaceholder="$t('Select')"
            :status="states.departments"
            :feedback="$t('RequiredField')"
            :datalist="departmentsList"
            :selectedItems.sync="departments"
            :searchPlaceholder="$t('message.SearchPlaceholderDefault')"
          />
        </b-col>
        <b-col
          md="12"
          class="form-group"
        >
          <Multiselect
            :label="$t('PeoplePage.Functions')"
            :fieldPlaceholder="$t('Select')"
            :status="states.functions"
            :feedback="$t('RequiredField')"
            :datalist="functionsList"
            :selectedItems.sync="functions"
            :searchPlaceholder="$t('message.SearchPlaceholderDefault')"
            :disabled="departments.length === 0"
          />
        </b-col>
      </template>

      <template #footer>
        <div class="separator"></div>
        <div
          v-if="device === 'mobile' && !modalTitle.includes('Edit')"
          class="step-action"
        >
          <div class="d-flex justify-content-between">
            <div :class="['step-collapse', isStepBlockOpenned ? 'not-collapsed' : 'collapsed']">
              {{ `1 ${$t('Of')} 2`
              }}<span
                id="step-collapse"
                @click="openStepBlock()"
                class="icon"
              >
                <ArrowDownIcon
              /></span>
            </div>
            <div class="text">{{ $t(modalTitle) }}</div>
          </div>
          <div class="step-progress">
            <div class="first-step step-active"></div>
            <div class="second-step"></div>
          </div>
          <b-collapse :visible="isStepBlockOpenned">
            <div class="progress-block">
              <div class="separator-progress"></div>
              <div class="detail-block">
                <div class="info-block step-active">
                  <div class="icon"><InfoIcon /></div>
                  <div class="text">{{ $t('PeoplePage.GeneralInformation') }}</div>
                </div>
                <div class="vertical-separator step-active"></div>
                <div class="login-block">
                  <div class="icon"><LockIcon /></div>
                  <div class="text">{{ $t('IntegrationPage.Login') }}</div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="separator-progress"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex buttons">
            <b-button
              class="btn btn-cancel btn-footer btn-cancel-outline"
              @click="emitCancel()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              v-if="!modalTitle.includes('Edit')"
              class="btn btn-save btn-footer"
              @click="nextStep()"
            >
              {{ $t('RefuseStatus.Next') }}
            </b-button>
            <b-button
              v-else
              class="btn btn-save btn-footer"
              @click="editPerson()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BFormGroup, BFormInput, BButton, BRow, BCol, BSidebar, BCollapse } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import modalStepByStep from './ModalStepByStep.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Multiselect from 'components-code';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      modalStepByStep,
      BCollapse,
      Multiselect,
      AddRole: () => import('@core/assets/icons/add-circle.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      ConfirmIcon: () => import('@core/assets/icons/confirm-icon.svg'),
      RemoveRole: () => import('@core/assets/icons/red-line-icon.svg'),
      EditRole: () => import('@core/assets/icons/edit-role-icon.svg'),
      DeclineIcon: () => import('@core/assets/icons/decline-icon.svg'),
      PhoneIcon: () => import('@core/assets/icons/phone-icon.svg'),
      ArrowDownIcon: () => import('@core/assets/icons/arrow-down.svg'),
      EmailIcon: () => import('@core/assets/icons/email-icon.svg'),
      InfoIcon: () => import('@core/assets/icons/info-icon.svg'),
      LockIcon: () => import('@core/assets/icons/lock-icon.svg')
    },
    props: {
      currentGuid: {
        type: String,
        default: ''
      },
      errorCode: {
        type: Array,
        default: []
      },
      departmentsList: {
        type: Array,
        default: []
      },
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      personSelected: {
        type: Object,
        default: null
      },
      userInfos: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        forceRenderAddedList: true,
        isOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isStepBlockOpenned: false,
        departments: [],
        functions: [],
        functionsList: [],
        prefix: localStorage.getItem('prefix'),
        flagEmailEqual: false,
        flagPhoneEqual: false,
        states: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          departments: null
        }
      };
    },
    mounted() {
      this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('people', ['UPDATE_MODAL_ADD_EDIT_TOGGLE', 'UPDATE_MODAL_RESET_TOGGLE']),
      async getFunctionsByDepartament(v) {
        if (v.length) {
          await this.$http2
            .get(`/api/myconfig/user/function/context?departmentIds=${v}`)
            .then((response) => {
              const unique_items = {};
              for (const item of response.data.data) {
                const item_id = item.id;
                const item_name = item.name;

                if (!(item_id in unique_items)) {
                  unique_items[item_id] = { value: item_id, text: item_name };
                }
              }
              const functionsList = Object.values(unique_items);

              functionsList.sort((a, b) => a.text.localeCompare(b.text));

              this.functionsList = functionsList;
              if (response.data.data.functions.length === 0) this.functions = [];
            });
        }
      },
      openStepBlock() {
        this.isStepBlockOpenned = !this.isStepBlockOpenned;
      },
      callAction() {
        window.location.assign(`tel:${this.phone}`);
      },
      sendEmailAction() {
        window.location.assign(`mailto:${this.email}`);
      },
      getEditInfo() {
        if (this.modalTitle.includes('Edit')) {
          const newPhone = this.personSelected.phone.replace(/[\(\)\s\-]/g, '');
          this.firstName = this.personSelected.first_name;
          this.lastName = this.personSelected.last_name;
          this.email = this.personSelected.email;
          this.phone = newPhone;
          this.departments = this.personSelected.departments.map((e) => e.id);
          this.functions = this.personSelected.functions.map((e) => e.id);
        }

        if (Object.keys(this.userInfos).length !== 0) {
          this.firstName = this.userInfos.first_name;
          this.lastName = this.userInfos.last_name;
          this.email = this.userInfos.email;
          this.phone = this.userInfos.phone;
          this.departments = this.userInfos.departments_ids;
          this.functions = this.userInfos.functions_ids;
        }

        if (this.errorCode.includes(64)) {
          this.flagEmailEqual = true;
          this.states.email = false;
        }
        if (this.errorCode.includes(72)) {
          this.flagPhoneEqual = true;
          this.states.phone = false;
        }
      },
      onHidden() {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      },
      emitCancel() {
        this.$emit('clearUserInfo');
        this.$emit('clearErroCode');
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
        this.clearValidations();
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      clearFields() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
        this.departments = [];
        this.functions = [];
      },
      clearValidations() {
        this.states.firstName = null;
        this.states.lastName = null;
        this.states.email = null;
        this.states.phone = null;
        this.states.departments = null;
        this.states.functions = null;
      },
      fieldsValidate() {
        this.clearValidations();

        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const regexPhone = /^\d{11}$/;
        const newPhone = this.phone.replace(/[\(\)\s\-]/g, '');

        const validFirstName = (this.states.firstName = this.firstName !== '');
        const validLastName = (this.states.lastName = this.lastName !== '');
        const validEmail =
          (this.states.email = regexEmail.test(this.email) || this.email === '') &&
          this.flagEmailEqual === false
            ? true
            : false;
        const validPhone = (this.states.phone =
          (regexPhone.test(newPhone) || this.phone === '') && this.flagPhoneEqual === false);
        const validFunctions = (this.states.functions = this.functions.length > 0);
        const validDepartments = (this.states.departments = this.departments.length > 0);

        return (
          validFirstName &&
          validLastName &&
          validEmail &&
          validPhone &&
          validFunctions &&
          validDepartments
        );
      },
      nextStep() {
        this.flagEmailEqual = false;
        this.flagPhoneEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        const payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          departments_ids: this.departments.map((e) => {
            if (e.value) return e.value;
            return e;
          }),
          functions_ids: this.functions.map((e) => (e.value ? e.value : e))
        };
        this.$emit('nextStep', payload);
      },
      async editPerson() {
        this.flagEmailEqual = false;
        this.flagPhoneEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone.replace(/[\(\)\s\-]/g, ''),
          departmentIds: this.departments.map((e) => {
            if (e.value) return e.value;
            return e;
          }),
          functionIds: this.functions.map((e) => (e.value ? e.value : e))
        };

        await this.$http2
          .put(`/api/myconfig/user/${this.currentGuid}`, payload)
          .then((response) => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('PeoplePage.SavedChanges'),
              'success'
            );
            this.$emit('updateList');
            this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              if (e.code === 64) {
                this.flagEmailEqual = true;
              } else if (e.code === 72) {
                this.flagPhoneEqual = true;
              } else {
                this.showToast(
                  this.$t('Error'),
                  'FrownIcon',
                  error.response.data.message,
                  'danger'
                );
              }
            });
            this.fieldsValidate();
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    },
    computed: {
      ...mapState('people', {
        isModalAddEditOpen: 'isModalAddEditOpen'
      })
    },
    watch: {
      isModalAddEditOpen(v) {
        this.isOpen = v;
        this.clearFields();
        this.clearValidations();
        if (v) {
          this.getEditInfo();
        } else {
          this.isStepBlockOpenned = false;
          this.flagEmailEqual = false;
          this.flagPhoneEqual = false;
        }
      },
      departments(v) {
        if (v && v.length === 0) {
          (this.functions = []), (this.functionsList = []);
        }
        this.getFunctionsByDepartament(v);
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-add-people,
  .sidebar-edit-people {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: none;
      border-left: 1px solid #cfc4be;
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .single-select {
            &.chip-responsible {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100% !important;
              border: none;

              .multiselect-invalid-feedback {
                margin-top: 3.5px;
              }

              .multiselect-component-single {
                .multiselect__tags {
                  height: auto !important;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  .multiselect__input {
                    margin-bottom: 10px;
                  }

                  .multiselect__placeholder {
                    color: #cfc4be;
                  }

                  .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;
                    height: auto !important;

                    .multiselect__tag-icon {
                      line-height: 21px;

                      &:hover {
                        background-color: #974900;
                        text-decoration: none;
                        opacity: 0.75;

                        &:after {
                          color: #000;
                        }
                      }

                      &:after {
                        color: #fff;
                      }
                    }
                  }
                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                }

                .multiselect__select {
                  height: 36px;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              &.focus {
                box-shadow: none;
              }
            }
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }
        .input-email,
        .input-phone {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-inline: 16px;
          gap: 16px;

          .form-group {
            width: inherit;
          }
        }

        .icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 0px;
          margin-top: 7px;
          max-width: 38px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #974900;

          &.btn-phone,
          &.btn-email {
            background-color: transparent !important;
            color: #974900 !important;
            border: 1px solid #974900 !important;

            &:hover {
              background-color: #ffede2 !important;
            }

            &:active {
              background-color: #ffdbc4 !important;
            }
          }
          &.mandatory {
            margin-bottom: 18px !important;
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .step-action {
      padding: 16px 0;

      .step-collapse {
        padding-left: 16px;
      }

      .step-collapse,
      .text {
        display: flex;
        gap: 8px;
        color: #4c4541;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        .icon {
          svg {
            width: 16px;
            height: 16px;
            fill: #974900;
          }
        }

        &.not-collapsed {
          svg {
            transform: rotate(-180deg);
            transition: all 0.3s;
          }
        }

        &.collapsed {
          svg {
            transform: rotate(0);
            transition: all 0.3s;
          }
        }
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
      }

      .step-progress {
        display: flex;
        gap: 4px;
        padding: 4px 16px 0;
        .first-step,
        .second-step {
          width: 50%;
          height: 4px;
          border-radius: 4px;
          background: #f4eee8;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .progress-block {
      padding-top: 16px;

      .detail-block {
        padding: 16px 16px 0;

        .info-block,
        .login-block {
          display: flex;
          align-items: center;
          gap: 8px;
          .icon {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 7px;
            border-radius: 50%;
            border: 1px solid #cfc4be;

            svg {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }

          .text {
            overflow: hidden;
            color: #cfc4be;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
          }

          &.step-active {
            .icon {
              border-color: #974900;
              svg {
                fill: #974900;
              }
            }

            .text {
              color: #974900;
            }
          }

          &.next-step {
            .icon {
              border-color: #66bb6a;
              background: #66bb6a;
              svg {
                fill: #fff;
              }
            }

            .text {
              color: #66bb6a;
            }
          }
        }

        .vertical-separator {
          margin-left: 14px;
          width: 1px;
          height: 16px;
          background: #cfc4be;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .separator-progress {
      width: 100%;
      height: 1px;
      background: #eee;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid transparent !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
  .sidebar-add-people {
    .b-sidebar {
      .b-sidebar-body {
        padding: 0 0 16px;
      }
    }
  }
  .sidebar-edit-people {
    .b-sidebar {
      .b-sidebar-body {
        padding: 16px 0;
      }
    }
  }
</style>
