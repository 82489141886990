<template>
  <div class="d-flex">
    <modal-step-by-step
      v-if="device !== 'mobile' && isAdd"
      :modalTitle="'PeoplePage.AddPerson'"
      :isOpen="isOpen"
      :stepPosition="2"
    />
    <b-sidebar
      id="reset-sidebar-right"
      class="sidebar-reset-password"
      right
      bg-variant="white"
      :width="
        device === 'desktop' ? '407px' : device === 'tablet' ? (isAdd ? '60vw' : '407px') : '100vw'
      "
      v-model="isOpen"
      @hidden="onHidden"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <div class="sidebar-title">
            {{ $t(modalTitle) }}
          </div>
          <span
            class="close-icon"
            @click="emitCancel()"
          >
            <CloseIcon />
          </span>
        </div>
        <div
          v-if="!isAdd"
          class="separator"
        ></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.User')"
            label-for="userName"
            :invalid-feedback="flagUserEqual ? $t('PeoplePage.EqualUserName') : $t('RequiredField')"
          >
            <b-form-input
              id="userName"
              v-model="userName"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.userName"
            />
          </b-form-group>
        </b-col>
        <div class="check-options">
          <div class="checkbox-block">
            <div
              :class="['checkbox-container', autoPassword ? 'checked' : '', '__no-action-default']"
              @click="autoPassword = !autoPassword"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.AutoPassword') }}</span>
          </div>
          <div
            v-if="!autoPassword"
            class="password-block"
          >
            <div class="requirements">
              <span class="text">{{ $t('PeoplePage.PasswordRequirements') }}</span>
              <div class="rules">
                <span :class="rule_1 ? 'is-valid' : 'not-valid'"
                  ><DotIcon v-if="!rule_1" /><CheckCircleIcon v-else />{{
                    $t('PeoplePage.MinimumChar')
                  }}</span
                >
                <span :class="rule_2 ? 'is-valid' : 'not-valid'"
                  ><DotIcon v-if="!rule_2" /><CheckCircleIcon v-else />{{
                    $t('PeoplePage.OneNumber')
                  }}</span
                >
                <span :class="rule_3 ? 'is-valid' : 'not-valid'"
                  ><DotIcon v-if="!rule_3" /><CheckCircleIcon v-else />{{
                    $t('PeoplePage.OneSpecialChar')
                  }}</span
                >
              </div>
            </div>
            <InputText
              input-i-d="password-input"
              :vmodel.sync="password"
              :field-label="$t('IntegrationPage.Password')"
              :field-placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.password"
              :invalid-feedback="$t('RequiredField')"
              input="password"
              @change="validatePassword"
            />
          </div>
          <div class="checkbox-block">
            <div
              :class="[
                'checkbox-container',
                askChangePassword ? 'checked' : '',
                '__no-action-default'
              ]"
              @click="askChangePassword = !askChangePassword"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.AskChangePassword') }}</span>
          </div>
          <div class="checkbox-block">
            <div
              :class="['checkbox-container', sendPassword ? 'checked' : '', '__no-action-default']"
              @click="sendPassword = !sendPassword"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.SendPassword') }}</span>
          </div>
          <div
            v-if="sendPassword"
            class="email-block"
          >
            <b-form-group
              :label="$t('PeoplePage.LabelEmail')"
              label-for="email"
              :invalid-feedback="
                flagEmailPasswordEqual
                  ? $t('PeoplePage.EqualEmail')
                  : email == ''
                  ? $t('RequiredField')
                  : $t('PeoplePage.InvalidEmail')
              "
              class="m-0"
            >
              <b-form-input
                id="email"
                v-model="email"
                :placeholder="$t('ResourceStatus.Placeholder')"
                :state="states.email"
                @input="
                  () => {
                    flagEmailPasswordEqual = false;
                    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    states.email = email !== '' && regexEmail.test(email) ? true : false;
                    return email;
                  }
                "
              />
            </b-form-group>
          </div>
        </div>
        <div class="pin-block check-options">
          <div class="title">
            <b-form-checkbox
              v-model="pin_switch"
              name="data-check-button"
              switch
              class="check-button"
            />
            <div class="text">PIN</div>
          </div>
          <div class="subtitle">{{ $t('PeoplePage.PinText') }}</div>
          <div
            v-if="pin_switch"
            class="checkbox-block"
          >
            <div
              :class="['checkbox-container', autoPin ? 'checked' : '', '__no-action-default']"
              @click="autoPin = !autoPin"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.AutoPin') }}</span>
          </div>
          <div
            v-if="!autoPin && pin_switch"
            class="password-block"
          >
            <div class="requirements">
              <span class="text">{{ $t('PeoplePage.PinRequirements') }}</span>
              <div class="rules">
                <span :class="pin_rule_1 ? 'is-valid' : 'not-valid'"
                  ><DotIcon v-if="!pin_rule_1" /><CheckCircleIcon v-else />{{
                    $t('PeoplePage.NumberValidation')
                  }}</span
                >
                <span :class="pin_rule_2 ? 'is-valid' : 'not-valid'"
                  ><DotIcon v-if="!pin_rule_2" /><CheckCircleIcon v-else />{{
                    $t('PeoplePage.CantSequence')
                  }}</span
                >
              </div>
            </div>
            <InputText
              input-i-d="password-input"
              :vmodel.sync="pin"
              field-label="PIN"
              :field-placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.pin"
              :invalid-feedback="$t('RequiredField')"
              input="password"
              max-length="4"
              @change="validatePin"
              @keydown="preventIfKeyIsLetter"
            />
          </div>
          <div
            v-if="pin_switch"
            class="checkbox-block"
          >
            <div
              :class="['checkbox-container', askChangePin ? 'checked' : '', '__no-action-default']"
              @click="askChangePin = !askChangePin"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.AskChangePin') }}</span>
          </div>
          <div
            v-if="pin_switch"
            class="checkbox-block"
          >
            <div
              :class="['checkbox-container', sendPin ? 'checked' : '', '__no-action-default']"
              @click="sendPin = !sendPin"
            >
              <span class="icon-checkbox"><CheckIcon /></span>
            </div>
            <span class="text-checkbox">{{ $t('PeoplePage.SendPin') }}</span>
          </div>
          <div
            v-if="sendPin && pin_switch"
            class="email-block"
          >
            <b-form-group
              :label="$t('PeoplePage.LabelEmailPin')"
              label-for="email-pin"
              :invalid-feedback="
                flagEmailPinEqual
                  ? $t('PeoplePage.EqualEmail')
                  : email_pin == ''
                  ? $t('RequiredField')
                  : $t('PeoplePage.InvalidEmail')
              "
              class="m-0"
            >
              <b-form-input
                id="email-pin"
                v-model="email_pin"
                :placeholder="$t('ResourceStatus.Placeholder')"
                :state="states.email_pin"
                @input="
                  () => {
                    flagEmailPinEqual = false;
                    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    states.email_pin =
                      email_pin !== '' && regexEmail.test(email_pin) ? true : false;
                    return email_pin;
                  }
                "
              />
            </b-form-group>
          </div>
        </div>
      </template>

      <template #footer>
        <div
          v-if="device === 'mobile' && isAdd"
          class="separator"
        ></div>
        <div
          v-if="device === 'mobile' && isAdd"
          class="step-action"
        >
          <div class="d-flex justify-content-between">
            <div :class="['step-collapse', isStepBlockOpenned ? 'not-collapsed' : 'collapsed']">
              {{ `2 ${$t('Of')} 2`
              }}<span
                id="step-collapse"
                @click="openStepBlock()"
                class="icon"
              >
                <ArrowDownIcon
              /></span>
            </div>
            <div class="text">{{ $t(modalTitle) }}</div>
          </div>
          <div class="step-progress">
            <div class="first-step next-step"></div>
            <div class="second-step step-active"></div>
          </div>
          <b-collapse :visible="isStepBlockOpenned">
            <div class="progress-block">
              <div class="separator-progress"></div>
              <div class="detail-block">
                <div class="info-block next-step">
                  <div class="icon"><InfoIcon /></div>
                  <div class="text">{{ $t('PeoplePage.GeneralInformation') }}</div>
                </div>
                <div class="vertical-separator next-step"></div>
                <div class="login-block step-active">
                  <div class="icon"><LockIcon /></div>
                  <div class="text">{{ $t('IntegrationPage.Login') }}</div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <b-col
          md="12"
          :class="['footer-sidebar', isAdd ? 'add-person' : '']"
        >
          <div :class="['d-flex', !isAdd ? 'edit-buttons' : 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer', !isAdd ? 'btn-cancel-outline' : '']"
              @click="emitCancel()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              v-if="isAdd"
              class="btn-previous btn-footer"
              @click="previousStep()"
            >
              {{ $t('PeoplePage.Previous') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BSidebar,
    BCollapse,
    BFormCheckbox
  } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import modalStepByStep from './ModalStepByStep.vue';
  import InputText from '@core/components/input-text/index.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      BFormCheckbox,
      BCollapse,
      modalStepByStep,
      InputText,
      AddRole: () => import('@core/assets/icons/add-circle.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      SingleSelect: () => import('@core/components/multiselect/SingleSelect.vue'),
      ConfirmIcon: () => import('@core/assets/icons/confirm-icon.svg'),
      RemoveRole: () => import('@core/assets/icons/red-line-icon.svg'),
      EditRole: () => import('@core/assets/icons/edit-role-icon.svg'),
      DeclineIcon: () => import('@core/assets/icons/decline-icon.svg'),
      PhoneIcon: () => import('@core/assets/icons/phone-icon.svg'),
      ArrowDownIcon: () => import('@core/assets/icons/arrow-down.svg'),
      CheckIcon: () => import('@core/assets/icons/check-icon.svg'),
      CheckCircleIcon: () => import('@core/assets/icons/check-circle-icon.svg'),
      EmailIcon: () => import('@core/assets/icons/email-icon.svg'),
      DotIcon: () => import('@core/assets/icons/dot-icon.svg'),
      InfoIcon: () => import('@core/assets/icons/info-icon.svg'),
      LockIcon: () => import('@core/assets/icons/lock-icon.svg')
    },
    props: {
      currentGuid: {
        type: String,
        default: ''
      },
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      personSelected: {
        type: Object,
        default: null
      },
      isAdd: {
        type: Boolean,
        default: false
      },
      userInfos: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        isOpen: false,
        userName: '',
        email: '',
        email_pin: '',
        password: '',
        pin: '',
        prefix: localStorage.getItem('prefix'),
        flagUserEqual: false,
        flagEmailPasswordEqual: false,
        flagEmailPinEqual: false,
        autoPassword: true,
        sendPassword: true,
        askChangePassword: true,
        isStepBlockOpenned: false,
        rule_1: false,
        rule_2: false,
        rule_3: false,
        pin_switch: false,
        autoPin: true,
        sendPin: true,
        askChangePin: true,
        pin_rule_1: false,
        pin_rule_2: false,
        states: {
          userName: null,
          email: null,
          email_pin: null,
          password: null,
          pin: null
        }
      };
    },
    mounted() {
      this.UPDATE_MODAL_RESET_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('people', [
        'UPDATE_MODAL_RESET_TOGGLE',
        'UPDATE_MODAL_ADD_EDIT_TOGGLE',
        'UPDATE_USER_LOGIN'
      ]),
      preventIfKeyIsLetter(event) {
        const input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'F5'];
        const keyPressed = event.key;
        const keyCode = event.keyCode ?? event.which;

        if (!input.includes(keyPressed) && keyCode != 8) event.preventDefault();
      },
      fillLoginFields() {
        if (this.userLoginInfo) {
          this.userName = this.userLoginInfo.userName;
          this.email = this.userLoginInfo.email;
          this.email_pin = this.userLoginInfo.email_pin;
          this.password = this.userLoginInfo.password;
          this.pin = this.userLoginInfo.pin;
          this.autoPassword = this.userLoginInfo.autoPassword;
          this.sendPassword = this.userLoginInfo.sendPassword;
          this.askChangePassword = this.userLoginInfo.askChangePassword;
          this.pin_switch = this.userLoginInfo.pin_switch;
          this.autoPin = this.userLoginInfo.autoPin;
          this.sendPin = this.userLoginInfo.sendPin;
          this.askChangePin = this.userLoginInfo.askChangePin;
          this.rule_1 = this.userLoginInfo.rule_1;
          this.rule_2 = this.userLoginInfo.rule_2;
          this.rule_3 = this.userLoginInfo.rule_3;
          this.pin_rule_1 = this.userLoginInfo.pin_rule_1;
          this.pin_rule_2 = this.userLoginInfo.pin_rule_2;
        }
      },
      validatePassword() {
        this.rule_1 = this.password.length >= 6;
        this.rule_2 = this.checkIfHasNumberInString(this.password);
        this.rule_3 = this.checkIfHasSpecialCharacterInString(this.password);
      },
      validatePin() {
        this.pin_rule_1 = this.pin.length === 4;
        this.pin_rule_2 = !this.checkIfIsNumberSequence(this.pin);
      },
      checkIfIsNumberSequence(value) {
        const string = typeof value === Number ? value.toString() : value;
        const listNumber = string.split('').map(Number);
        const length = listNumber.length;

        let isCrescentSequence = true;
        let isDerescentSequence = true;

        if (length <= 1) return false;

        for (let i = 1; i < listNumber.length; i++) {
          if (listNumber[i] !== listNumber[i - 1] + 1) {
            isCrescentSequence = false;
            break;
          }
        }

        for (let i = 1; i < listNumber.length; i++) {
          if (listNumber[i] !== listNumber[i - 1] - 1) {
            isDerescentSequence = false;
            break;
          }
        }

        return isCrescentSequence || isDerescentSequence;
      },
      checkIfHasNumberInString(string) {
        const re = /\d/;
        return re.test(string);
      },
      checkIfHasSpecialCharacterInString(string) {
        const re = /[!@#$%^&*(),.?":{}|<>]/;
        return re.test(string);
      },
      openStepBlock() {
        this.isStepBlockOpenned = !this.isStepBlockOpenned;
      },
      previousStep(code = null) {
        this.UPDATE_USER_LOGIN({
          userName: this.userName,
          email: this.email,
          email_pin: this.email_pin,
          password: this.password,
          pin: this.pin,
          autoPassword: this.autoPassword,
          sendPassword: this.sendPassword,
          askChangePassword: this.askChangePassword,
          pin_switch: this.pin_switch,
          autoPin: this.autoPin,
          sendPin: this.sendPin,
          askChangePin: this.askChangePin,
          rule_1: this.rule_1,
          rule_2: this.rule_2,
          rule_3: this.rule_3,
          pin_rule_1: this.pin_rule_1,
          pin_rule_2: this.pin_rule_2
        });
        this.$emit('previousStep', code);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      onHidden() {
        this.UPDATE_MODAL_RESET_TOGGLE(false);
      },
      emitCancel() {
        this.$emit('clearUserInfo');
        this.UPDATE_USER_LOGIN(null);
        this.UPDATE_MODAL_RESET_TOGGLE(false);
      },
      clearFields() {
        this.userName = '';
        this.email = '';
        this.email_pin = '';
        this.password = '';
        this.pin = '';
        this.autoPassword = true;
        this.sendPassword = true;
        this.askChangePassword = true;
        this.pin_switch = false;
        this.autoPin = true;
        this.sendPin = true;
        this.askChangePin = true;
        this.rule_1 = false;
        this.rule_2 = false;
        this.rule_3 = false;
        this.pin_rule_1 = false;
        this.pin_rule_2 = false;
      },
      clearValidations() {
        this.states.userName = null;
        this.states.email = null;
        this.states.email_pin = null;
      },
      fieldsValidate() {
        this.clearValidations();

        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const validUser = (this.states.userName =
          this.userName && this.flagUserEqual === false ? true : false);

        let validPassword = true;
        let validPin = true;
        let validEmail = true;
        let validEmailPin = true;

        if (!this.autoPassword) {
          validPassword = this.states.password = this.rule_1 && this.rule_2 && this.rule_3;
        }

        if (!this.autoPin && this.pin_switch) {
          validPin = this.states.pin = this.pin_rule_1 && this.pin_rule_2;
        }

        if (this.sendPassword) {
          validEmail = this.states.email =
            this.email !== '' &&
            regexEmail.test(this.email) &&
            this.flagEmailPasswordEqual === false
              ? true
              : false;
        }

        if (this.sendPin && this.pin_switch) {
          validEmailPin = this.states.email_pin =
            this.email_pin !== '' &&
            regexEmail.test(this.email_pin) &&
            this.flagEmailPinEqual === false
              ? true
              : false;
        }

        return validUser && validPassword && validPin && validEmail && validEmailPin;
      },
      async onSave() {
        this.flagUserEqual = false;
        this.flagEmailPasswordEqual = false;
        this.flagEmailPinEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        if (this.isAdd) {
          this.userInfos.phone = this.userInfos.phone.replace(/[\(\)\s\-]/g, '');

          const payload = {
            user_infos: this.userInfos,
            user_login: {
              user: this.userName,
              generate_password: this.autoPassword,
              password_change_required: this.askChangePassword,
              send_email_password: this.sendPassword,
              send_password_email_to: this.email ? this.email : null,
              password: this.password ? this.password : null,
              generate_pin: this.autoPin && this.pin_switch ? this.autoPin : null,
              pin_change_required: this.askChangePin && this.pin_switch ? this.askChangePin : null,
              send_email_pin: this.sendPin && this.pin_switch ? this.sendPin : null,
              send_pin_email_to: this.email_pin && this.pin_switch ? this.email_pin : null,
              pin: this.pin && this.pin_switch ? this.pin : null
            }
          };

          await this.$http
            .post(`/api/${this.prefix}/myconfig/user`, payload)
            .then((response) => {
              this.$emit('updateList');
              this.UPDATE_MODAL_RESET_TOGGLE(false);
              this.UPDATE_USER_LOGIN(null);
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('PeoplePage.PersonCreated'),
                'success'
              );
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 55) {
                  this.flagUserEqual = true;
                } else if (e.code === 64) {
                  this.previousStep(e.code);
                } else if (e.code === 72) {
                  this.previousStep(e.code);
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        } else if (!this.isAdd) {
          const payload = {
            user_login: {
              user: this.userName,
              generate_password: this.autoPassword,
              password_change_required: this.askChangePassword,
              send_email_password: this.sendPassword,
              send_password_email_to: this.email ? this.email : null,
              password: this.password ? this.password : null,
              generate_pin: this.autoPin && this.pin_switch ? this.autoPin : null,
              pin_change_required: this.askChangePin && this.pin_switch ? this.askChangePin : null,
              send_email_pin: this.sendPin && this.pin_switch ? this.sendPin : null,
              send_pin_email_to: this.email_pin && this.pin_switch ? this.email_pin : null,
              pin: this.pin && this.pin_switch ? this.pin : null
            }
          };

          await this.$http
            .put(`/api/${this.prefix}/myconfig/user/${this.currentGuid}`, payload)
            .then((response) => {
              this.UPDATE_MODAL_RESET_TOGGLE(false);
              this.$emit('updateList');
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('PeoplePage.SavedChanges'),
                'success'
              );
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 55) {
                  this.flagUserEqual = true;
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      }
    },
    computed: {
      ...mapState('people', {
        isModalResetOpen: 'isModalResetOpen',
        userLoginInfo: 'userLoginInfo'
      })
    },
    watch: {
      isModalResetOpen(v) {
        this.isOpen = v;
        this.fillLoginFields();
        if (!v) {
          this.clearFields();
          this.clearValidations();
        }
      },
      personSelected(v) {
        if (v) this.userName = v.user;
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-reset-password {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: none;
      border-left: 1px solid #cfc4be;
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .single-select {
            &.chip-responsible {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100% !important;
              border: none;

              .multiselect-invalid-feedback {
                margin-top: 3.5px;
              }

              .multiselect-component-single {
                .multiselect__tags {
                  height: auto !important;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  .multiselect__input {
                    margin-bottom: 10px;
                  }

                  .multiselect__placeholder {
                    color: #cfc4be;
                  }

                  .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;
                    height: auto !important;

                    .multiselect__tag-icon {
                      line-height: 21px;

                      &:hover {
                        background-color: #974900;
                        text-decoration: none;
                        opacity: 0.75;

                        &:after {
                          color: #000;
                        }
                      }

                      &:after {
                        color: #fff;
                      }
                    }
                  }
                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                }

                .multiselect__select {
                  height: 36px;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              &.focus {
                box-shadow: none;
              }
            }
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        .check-options {
          padding: 0 16px 16px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .checkbox-block {
            display: flex;
            gap: 8px;
            .checkbox-container {
              display: flex;
              background-color: #fff;
              border: 1px solid #cfc4be;
              border-radius: 3px;
              cursor: pointer;
              width: 18px;
              height: 18px;

              .icon-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  transform: scale(0.8);
                  width: 18px;
                  height: 18px;
                }
              }
              &.checked {
                background-color: #974900;
                display: block;
              }
            }

            .text-checkbox {
              color: #4c4541;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;

              @media (max-width: 480px) {
                font-size: 12px;
              }
            }
          }

          .email-block {
            padding-top: 8px;
          }
        }

        .password-block {
          padding-bottom: 8px;
          .requirements {
            padding: 8px 0 16px;

            .text {
              color: #998f8a;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;

              @media (max-width: 480px) {
                font-size: 12px;
              }
            }

            .rules {
              padding-top: 8px;

              span {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #998f8a;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;

                &.is-valid {
                  color: #4caf50;
                }

                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .pin-block {
          display: flex;
          flex-direction: column;
          padding: 0 16px 16px;
          border-top: 1px solid #eee;
          gap: 8px;
          .title {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-top: 16px;

            .text {
              color: #4c4541;
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;
            }

            .check-button {
              padding-block: 8px;
              line-height: 12px;
              min-height: 12px;
              width: 22px;

              .custom-control-label {
                margin: 0;
                line-height: 20px;
              }

              .custom-control-input ~ .custom-control-label::before {
                width: 22px;
                background-color: #ece0db;
                height: 12px;
              }
              .custom-control-input ~ .custom-control-label::after {
                left: 2px;
                top: 2px;
                width: 8px;
                height: 8px;
              }

              .custom-control-input:checked ~ .custom-control-label::before {
                background-color: #974900;
              }

              .custom-control-input:checked ~ .custom-control-label::after {
                left: -8px;
              }
            }
          }

          .subtitle {
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 16px;

            @media (max-width: 480px) {
              font-size: 12px;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .step-action {
      padding: 16px 0;

      .step-collapse {
        padding-left: 16px;
      }

      .step-collapse,
      .text {
        display: flex;
        gap: 8px;
        color: #4c4541;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        .icon {
          svg {
            width: 16px;
            height: 16px;
            fill: #974900;
          }
        }

        &.not-collapsed {
          svg {
            transform: rotate(-180deg);
            transition: all 0.3s;
          }
        }

        &.collapsed {
          svg {
            transform: rotate(0);
            transition: all 0.3s;
          }
        }
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
      }

      .step-progress {
        display: flex;
        gap: 4px;
        padding: 4px 16px 0;
        .first-step,
        .second-step {
          width: 50%;
          height: 4px;
          border-radius: 4px;
          background: #f4eee8;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .progress-block {
      padding-top: 16px;

      .detail-block {
        padding: 16px 16px 0;

        .info-block,
        .login-block {
          display: flex;
          align-items: center;
          gap: 8px;
          .icon {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 7px;
            border-radius: 50%;
            border: 1px solid #cfc4be;

            svg {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }

          .text {
            overflow: hidden;
            color: #cfc4be;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
          }

          &.step-active {
            .icon {
              border-color: #974900;
              svg {
                fill: #974900;
              }
            }

            .text {
              color: #974900;
            }
          }

          &.next-step {
            .icon {
              border-color: #66bb6a;
              background: #66bb6a;
              svg {
                fill: #fff;
              }
            }

            .text {
              color: #66bb6a;
            }
          }
        }

        .vertical-separator {
          margin-left: 14px;
          width: 1px;
          height: 16px;
          background: #cfc4be;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .separator-progress {
      width: 100%;
      height: 1px;
      background: #eee;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      border-top: 1px solid #cfc4be;

      &.add-person {
        border-color: #eee;
      }

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 33%;
          }
        }

        .edit-buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid transparent !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
